import { Component, OnInit, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.css']
})
export class EcommerceComponent implements OnInit, OnDestroy, PipeTransform {

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private router: Router) { }

  plan;
  url;
  private sub;
  transform(url) {
    console.log('Transformando URL:', url);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.plan = params['plan'];
      console.log('Parámetro de ruta "plan":', this.plan); // Log para verificar el parámetro de la ruta
      this.url = `https://www.vittal.com.ar/gestion/ecommerce/${this.plan}/`;
      console.log('URL generada para el iframe:', this.url); // Log para verificar la URL generada
      this.transform(this.url);
    });
  }

  ngOnDestroy() {
    console.log('Destruyendo el componente y cancelando la suscripción.'); // Log para indicar que se está destruyendo el componente
    this.sub.unsubscribe();
  }

}

