import { HttpErrorResponse } from '@angular/common/http';
import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataWpServiceTelefonosUtiles } from 'src/app/data-wp.service';
import { PostI } from 'src/app/post.interface';

@Component({
  selector: 'app-telefonos-utiles',
  templateUrl: './telefonos-utiles.component.html',
  styleUrls: ['./telefonos-utiles.component.css'],
  providers: [DataWpServiceTelefonosUtiles],
  encapsulation: ViewEncapsulation.None
})
export class TelefonosUtilesComponent implements OnInit {
  title;
  posts$: Observable<PostI[]>;
  isMobile: boolean;
  posts: PostI[];

  constructor(
    private postsService: DataWpServiceTelefonosUtiles,
    private router: Router,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private metaService: Meta
  ) {}


  
  ngOnInit() {
    this.postsService.getPosts().subscribe(
      (posts: PostI[]) => {
        this.posts = posts;        
      },
      (err: HttpErrorResponse) =>
        err.error instanceof Error
          ? console.log("An error occurred:", err.error.message)
          : console.log(
              `Backend returned code ${err.status}, body was: ${err.error}`
            )
    );

    this.posts$ = this.postsService.getPosts();
    this.posts$.subscribe((data) => {
      /*Levantamos MetaTags*/
      var titleSeo = (data[0].acf.seo.titulo);
      var descripcionSeo = (data[0].acf.seo.descripcion);
      var keywordsSeo = (data[0].acf.seo.keywords);
      /*Seteamos Metatags en header*/
      this.titleService.setTitle("Vittal - " + titleSeo);
      this.metaService.updateTag({
        name: "description",
        content: descripcionSeo,
      });
      this.metaService.updateTag({ name: "keywords", content: keywordsSeo });
      this.metaService.updateTag({ name: "robots", content: "index, follow" });
    });
    this.isMobile = window.innerWidth < 480 ? true : false;
  }

}
