import { Component, OnInit, Pipe, PipeTransform  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { DataWpServiceBannerDestacado } from '../../data-wp.service';
import { PostI } from '../../post.interface';


@Component({
  selector: "app-banner-destacado",
  templateUrl: "./banner-destacado.component.html",
  styleUrls: ["./banner-destacado.component.css"],
  providers: [DataWpServiceBannerDestacado],
})
export class BannerDestacadoComponent implements OnInit {
  constructor(
    private dataWp: DataWpServiceBannerDestacado,
    private sanitizer: DomSanitizer
  ) {}

  posts$: Observable<PostI[]>;
  isMobile: boolean;
  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnInit() {
    this.posts$ = this.dataWp.getPosts();
    this.posts$.subscribe((e) => {
      console.log(e);
    });

    window.innerWidth < 500 ? (this.isMobile = true) : (this.isMobile = false);
  }
}

