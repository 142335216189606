import { Component, OnInit  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { DataWpServiceSlider } from '../../data-wp.service';
import { PostI } from '../../post.interface';

@Component({
  selector: 'app-slider-mobile',
  templateUrl: './slider-mobile.component.html',
  styleUrls: ['./slider-mobile.component.css'],
  providers: [DataWpServiceSlider]
})
export class SliderMobileComponent implements OnInit {

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": true,
    "autoplay": true,
    "fade": true,
    "focusOnSelect": true,
    "autoplaySpeed": 5000,
    // "adaptiveHeight": true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1
        }
      }
    ]
  };

  constructor(
    private dataWp: DataWpServiceSlider, 
    private sanitizer: DomSanitizer
  ) { }

  posts$: Observable<PostI[]>;
  
  transform(html) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnInit() {
    this.posts$ = this.dataWp.getPosts();
    this.posts$.subscribe(e => {})
  }  

}
