import { Component, OnInit } from '@angular/core';
import { DataWpServiceBuscador } from '../../data-wp.service';
import { DataWpServiceBlog } from '../../data-wp.service';
import { PostI } from '../../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css'],
  providers: [DataWpServiceBuscador, DataWpServiceBlog]  
})
export class BuscadorComponent implements OnInit {


  posts: PostI[];
  postsBlog: PostI[];
  constructor(
   private postsService: DataWpServiceBuscador, 
   private postsServiceBlog: DataWpServiceBlog, 
   private router: Router
  ) { }

  filterPost = '';
  


  ngOnInit() {
    this.postsService.getPosts().subscribe( 
      (posts: PostI[]) => this.posts = posts, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));
    this.postsServiceBlog.getPosts().subscribe( 
        (postsBlog: PostI[]) => this.postsBlog = postsBlog, 
        (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));
  }
  selectPost(slug) {
  	this.router.navigate([slug]);
  }




  hide = true;
  buscadorShow(){    
      document.getElementById("search").classList.add("active");
      document.getElementById("backBuscador").classList.add("active");      
      this.hide = false;    
  }
  buscadorHide(){    
    document.getElementById("search").classList.remove("active");
    document.getElementById("backBuscador").classList.remove("active");
    this.hide = false;    
  }

}
