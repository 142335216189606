import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DataWpServiceLandings } from '../../data-wp.service';
import { PostI } from '../../post.interface';



@Component({
  selector: "app-landing-single",
  templateUrl: "./landing-single.component.html",
  styleUrls: ["./landing-single.component.css"],
  providers: [DataWpServiceLandings],
  encapsulation: ViewEncapsulation.None,
})
export class LandingSingleComponent implements OnInit {
  post: PostI;
  isBitrix: boolean;
  mobile: boolean;
  constructor(
    private postsService: DataWpServiceLandings,
    private route: ActivatedRoute
  ) { }

  getPost(slug) {
    this.postsService.getPost(slug).subscribe((res) => {
      console.log('Respuesta de la API:', res); // Log de la respuesta de la API
      if (res && res.length > 0) {
        this.post = res[0];
        if (this.post.acf) {
          this.isBitrix = this.post.acf["activarbitrix"] ? true : false;
        } else {
          console.warn('acf no está definido en el post:', this.post); // Log de advertencia si acf no está definido
        }
      } else {
        console.error('No se encontraron posts para el slug:', slug); // Log de error si no se encuentra el post
      }
    });
  }

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      let slug = params["slug"];
      this.getPost(slug);
    });

    window.innerWidth < 481 ? (this.mobile = true) : (this.mobile = false);
  }
}
