import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataWpServiceBlog, DataWpServiceTituloBlog, DataWpServiceCategorias } from '../../data-wp.service';
import { PostI } from '../../post.interface';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['../blog.component.css'],
  providers: [DataWpServiceBlog, DataWpServiceTituloBlog, DataWpServiceCategorias]

})
export class CategoriaComponent implements OnInit {
  postsTitulos: PostI[]; 
  post: PostI;/*NOMBRE DE CATEGORIA*/
  posts: PostI[];

  constructor( 
    private postsServiceTitulos: DataWpServiceTituloBlog,
    private postsService: DataWpServiceBlog, 
    private postsServiceSlugCat: DataWpServiceCategorias, 
    private route: ActivatedRoute,
    private router: Router ) { }

  getPost(slug){
      this.postsServiceSlugCat
        .getPost(slug)
        .subscribe(res => {
          this.post = res[0];
        });
  }

  /*CONFIGURACION DE POSTEOS DE WP*/
  ngOnInit() {

     /*LEVANTA NOMBRE CATEGORIA*/
     this.route.params.forEach((params: Params) => {
        let slug = params['slug'];
        this.getPost(slug)
     });
    
    this.postsServiceSlugCat.getPosts().subscribe( 
      (posts: PostI[]) => this.posts = posts, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));

      this.postsServiceTitulos.getPosts().subscribe( 
        (postsTitulos: PostI[]) => this.postsTitulos = postsTitulos, 
        (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));  

  }
  selectPost(slug) {
  	this.router.navigate([slug]);
  }


  }

