import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DataWpServicePages } from '../data-wp.service';
import { PostI } from '../post.interface';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [DataWpServicePages]
})
export class HomeComponent implements OnInit {

  constructor(private titleService: Title, 
    private metaService: Meta, private postsService: DataWpServicePages ) { }
    
    postsTitulos: PostI[]; 
    posts$: Observable<PostI[]>;
    posts: PostI[];
    mobile: boolean;

  ngOnInit() {
    window.innerWidth < 481 ? (this.mobile = true) : (this.mobile = false);    
    
    this.postsService.getPost("home").subscribe( 
      (posts: PostI[]) => {
        this.posts = posts
      let titleSeo = posts[0].acf.seo.titulo;
      let descripcionSeo = posts[0].acf.seo.descripcion;
      let keywordsSeo = posts[0].acf.seo.keywords;
      /*Seteamos Metatags en header*/
      this.titleService.setTitle( titleSeo );      
      this.metaService.updateTag( {name: 'keywords', content: keywordsSeo} );
      this.metaService.updateTag( {name: 'description', content: descripcionSeo} );
      this.metaService.updateTag( {name: 'robots', content: 'index, follow'} );      
      }, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));
      
    }
  }


