import { Component, OnInit, ViewEncapsulation, Input} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { phoneNumberValidator } from '../contacto/validators/phone-validator'
import { HttpClient } from '@angular/common/http';
import { DataWpServicePrecios } from '../data-wp.service';
import { PostI } from '../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-cotizacion",
  templateUrl: "./cotizacion.component.html",
  styleUrls: ["./cotizacion.component.css"],
  providers: [DataWpServicePrecios],
  encapsulation: ViewEncapsulation.None,
})
export class CotizacionComponent implements OnInit {
  @Input() codePricePlan; /*INPUT CON CODIGO DE PRECIO*/
  @Input() codeFormPlan: number; /*INPUT CON CODIGO DE FORMULARIO PARA ENVIAR*/
  @Input() tituloForm: string; /*INPUT CON TITULO DE FORMULARIO*/

  form: FormGroup; /* FORMUALRIO VALIDADORES, ETC */
  posts: PostI[]; /*Interfaz para levantar data precios de planes*/
  closeResult: string; /* SETEO DE VARIABLE PARA MODAL */
  mobile: boolean;
  bitrix: boolean;
  isHome: boolean;
  constructor(
    public formBuilder: FormBuilder,
    private postsService: DataWpServicePrecios,
    private router: Router,
    private modalService: NgbModal
  ) {}

  openModalCotizar(content) {
    this.modalService.open(content, {
      size: "xl",
      windowClass: "modal-cotizaciones",
    });
  }

  spanTop() {
    document.getElementById("areaSpan").classList.add("top");
    document.getElementById("contentSelect").classList.add("ok");
  }
  spanTopEdades() {
    document.getElementById("edadesSpan").classList.add("top");
  }

  ngOnInit() {
    this.postsService.getPosts().subscribe(
      (posts: PostI[]) => (this.posts = posts),
      (err: HttpErrorResponse) =>
        err.error instanceof Error
          ? console.log("An error occurred:", err.error.message)
          : console.log(
              `Backend returned code ${err.status}, body was: ${err.error}`
            )
    );
    window.innerWidth < 481 ? (this.mobile = true) : (this.mobile = false);
    this.bitrix = true;
    if (this.router.url === '/') {
      this.isHome = true;
    }else{
      this.isHome = false;
    }
  }
  selectPost(slug) {
    this.router.navigate([slug]);
  }

  /*APETURA DE MODAL */
  open(content) {
    //modal-dialog-centered
    this.modalService.open(content, {
      size: "xl",
      windowClass: "modal-cotizaciones",
    });
  }
}
