import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { DataWpServiceGestionOnline } from "src/app/data-wp.service";
import { GenericResponse } from "src/app/generic-response.interface";
import { AuthService } from "src/app/services/auth.service";
import { SessionStorageService } from "src/app/services/session-storage.service";

@Component({
  selector: "app-login-socio",
  templateUrl: "./login-socio.component.html",
  styleUrls: ["./login-socio.component.css"],
  providers: [AuthService],
})
export class LoginSocioComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  isHome;

  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;

  constructor(
    public formBuilder: FormBuilder,
    private authSrv: AuthService,
    private ssService: SessionStorageService,
    private spinner: NgxUiLoaderService,
    private serviceGo: DataWpServiceGestionOnline,
    private router: Router,
    private modalService: NgbModal
  ) {}

  get dniSocio() {
    return this.contactForm.get("dniSocio");
  }
  get emailSocio() {
    return this.contactForm.get("emailSocio");
  }

  responseHttp: GenericResponse;
  ischecked: boolean = false;
  dniOPass: string = "DNI";
  modalMessage: string;

  contactForm = this.formBuilder.group({
    checkbox: [""],
    dniSocio: ["", [Validators.required, Validators.pattern("^[0-9]{7,8}$")]],
    emailSocio: [
      "",
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    ],
  });

  /*Funcion cuando se hace submit*/
  submitForm() {
    let socio_dni = this.contactForm.value.dniSocio;
    let socio_email = this.contactForm.value.emailSocio;

    this.spinner.start();

    //this.authSrv.login(socio_dni, socio_email);
    this.serviceGo.login(socio_dni, socio_email).subscribe((res) => {
      this.responseHttp = res;
      if (this.responseHttp.success) {
        this.authSrv.save(this.responseHttp);
        this.ssService.clearRegister();
        this.router.navigate(["atencion-al-socio/gestion-online/socios"]);
      } else {
        this.spinner.stop();
        this.modalMessage = this.responseHttp.message;
        this.openModal(this.modalRespuesta);
      }
    });
  }

  /*Funcion para abrir registro socios*/
  abrirFormSocios() {
    document.getElementById("contentRegistro").classList.add("show");
    document.getElementById("registroSocios").classList.add("show");
    document.getElementById("contentLogin").classList.remove("show");
    // if (window.innerWidth < 760) {
    //   document.getElementById("titulosMobile").classList.add("none");
    // }
    this.ssService.clearHome();
    this.ssService.setRegister();
  }

  ngOnInit() {
    this.ssService.isHome().subscribe((e) => {
      this.isHome = e;
    });
  }

  ngAfterViewInit() {
    let dniForm = this.contactForm.get("dniSocio");
    let check: Observable<boolean> =
      this.contactForm.get("checkbox").valueChanges;
    check.subscribe((e) => {
      e ? (this.dniOPass = "Pasaporte") : (this.dniOPass = "DNI");
      this.ischecked = e;
      if (e) {
        dniForm.clearValidators();
        dniForm.setValidators([
          Validators.required,
          Validators.pattern("^([a-zA-Z]{3}[0-9]{6})$"),
        ]);
        dniForm.updateValueAndValidity();
      } else {
        dniForm.clearValidators();
        dniForm.setValidators([
          Validators.required,
          Validators.pattern("^[0-9]{7,8}$"),
        ]);
        dniForm.updateValueAndValidity();
      }
    });
  }

  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }
}
