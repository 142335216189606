import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { EmpresaData } from "src/app/shared/empresa-data.interface";

@Component({
  selector: "app-perfil-empresa",
  templateUrl: "./perfil-empresa.component.html",
  styleUrls: ["./perfil-empresa.component.css"],
})
export class PerfilEmpresaComponent implements OnInit {
  constructor(private authSrv: AuthService) {}
  datosEmpresa: EmpresaData;
  ngOnInit() {
    this.datosEmpresa = JSON.parse(sessionStorage.getItem("data"));
  }

  logout() {
    this.authSrv.logout();
  }
}
