import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { DataWpServiceFooter } from 'src/app/data-wp.service';
import { PostI } from 'src/app/post.interface';

@Component({
  selector: 'app-libro-quejas',
  templateUrl: './libro-quejas.component.html',
  styleUrls: ['./libro-quejas.component.css'],
  providers:[DataWpServiceFooter]
})
export class LibroQuejasComponent implements OnInit {

  constructor(private postsService: DataWpServiceFooter, private render: Renderer2) { }
  posts$: Observable<PostI[]>;
  post;
  scriptBitrix;
  ngOnInit() {

    this.posts$ = this.postsService.getPosts();
    this.posts$.subscribe(
      post => {
        console.log(post);
        
        this.post = post[0]
        this.scriptBitrix = this.post.acf.scriptbitrix;        
        let comienzoCadena = this.scriptBitrix.indexOf("inline") + 7;
        let finCadena = this.scriptBitrix.indexOf("data-skip-moving") - 2;
        let cadena = this.scriptBitrix.slice(comienzoCadena, finCadena).split("/");
        let formID = cadena[0];
        let formCode = cadena[1];
        
        const script = this.render.createElement("script");
        script.dataset["b24Form"] = `inline/` + formID + `/` + formCode;
        script.dataset["skipMoving"] = `true`;
        script.src =
          `https://cdn.bitrix24.es/b18116991/crm/form/loader_` + formID + `.js`;
        script.text = `
        (function(w,d,u){
          var s=d.createElement('script');
          s.async=true;s.src=u+'?'+(Date.now()/180000|0);
          var h=d.getElementsByTagName('script')[0];
          h.parentNode.insertBefore(s,h);        
          })  
    `;    
      this.render.appendChild(document.getElementById("contentAll"), script);
      }
    )    

  }
}
