import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { API_BASE_URL_V } from "src/app/data-wp.service";
import { AuthService } from "src/app/services/auth.service";
import { EmpresaData } from "src/app/shared/empresa-data.interface";

@Component({
  selector: "app-empresa-facturacion",
  templateUrl: "./empresa-facturacion.component.html",
  styleUrls: ["./empresa-facturacion.component.css"],
})
export class EmpresaFacturacionComponent implements OnInit {
  constructor(
    private authSrv: AuthService,
    private http: HttpClient,
    private modalService: NgbModal,
    private spinner: NgxUiLoaderService
  ) { }

  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;
  datosEmpresa: EmpresaData;
  modalMessage: string;
  facturas: any[];

  ngOnInit() {
    this.datosEmpresa = JSON.parse(sessionStorage.getItem("data"));

    this.spinner.start();
    this.http
      .get<any>(API_BASE_URL_V + "/empresa/facturas", {
        params: {
          empresa_codigo: this.datosEmpresa.empresa_codigo,
          empresa_dominio: this.datosEmpresa.empresa_dominio,
        },
      })
      .subscribe((res) => {
        this.spinner.stop();
        if (res.success) {
          if (res.data.facturas) {
            this.facturas = res.data.facturas;
          } else {
            this.modalMessage = "No hay facturas disponibles.";
            this.openModal(this.modalRespuesta);
          }
        } else {
          this.modalMessage = res.message;
          this.openModal(this.modalRespuesta);
        }
      });
  }

  logout() {
    this.authSrv.logout();
  }
  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }

  getMonth(fecha) {
    let formatofecha = fecha.split("/");
    let fechafinal =
      formatofecha[1] + "/" + formatofecha[0] + "/" + formatofecha[2];
    return new Intl.DateTimeFormat("es-ES", { month: "long" }).format(
      new Date(fechafinal)
    );
  }
  getYear(fecha) {
    let formatofecha = fecha.split("/");

    return formatofecha[2];
  }

  obtenerFacturaPDF(letra: string, numero: string) {
    console.log('Iniciando la descarga del PDF...');
    console.log(`Parámetros recibidos - Letra: ${letra}, Número: ${numero}, Dominio: ${this.datosEmpresa.empresa_dominio}`);

    this.spinner.start();
    this.http
      .post(API_BASE_URL_V + "/empresa/factura/pdf", {
        letra: letra,
        numero: numero,
        dominio: this.datosEmpresa.empresa_dominio,
      }, { responseType: 'blob' })
      .subscribe((res) => {
        console.log('Respuesta recibida de la API:', res);
        this.spinner.stop();

        // Verifica si la respuesta es un blob
        if (res instanceof Blob) {
          console.log('La respuesta es un Blob, procediendo a crear el PDF...');
          const blob = new Blob([res], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'factura.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          console.log('PDF descargado exitosamente.');
        } else {
          console.error('La respuesta no es un Blob. Verifica la respuesta de la API:', res);
          this.modalMessage = "Error: La respuesta no es un PDF válido.";
          this.openModal(this.modalRespuesta);
        }
      }, (error) => {
        this.spinner.stop();
        console.error('Error al realizar la solicitud:', error);
        this.modalMessage = "Error al obtener el PDF.";
        this.openModal(this.modalRespuesta);
      });
  }
}
