import { Component, Input, OnInit, Renderer2 } from "@angular/core";

@Component({
  selector: "app-form-contacto-bitrix",
  templateUrl: "./form-contacto-bitrix.component.html",
  styleUrls: ["./form-contacto-bitrix.component.css"],
})
export class FormContactoBitrixComponent implements OnInit {
  constructor(private render: Renderer2) {}
  @Input() scriptBitrix: string;
  @Input() tituloForm: string;

  ngOnInit() {
    let comienzoCadena = this.scriptBitrix.indexOf("inline") + 7;
    let finCadena = this.scriptBitrix.indexOf("data-skip-moving") - 2;
    let cadena = this.scriptBitrix.slice(comienzoCadena, finCadena).split("/");
    let formID = cadena[0];
    let formCode = cadena[1];

    const script = this.render.createElement("script");
    script.dataset["b24Form"] = `inline/` + formID + `/` + formCode;
    script.dataset["skipMoving"] = `true`;
    script.src =
      `https://cdn.bitrix24.es/b18116991/crm/form/loader_` + formID + `.js`;
    script.text = `
    (function(w,d,u){
      var s=d.createElement('script');
      s.async=true;s.src=u+'?'+(Date.now()/180000|0);
      var h=d.getElementsByTagName('script')[0];
      h.parentNode.insertBefore(s,h);        
      })  
`;
    this.render.appendChild(document.getElementById("contentAll"), script);
  }
}
