import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataWpServicePlanesChild, DataWpServicePlanes } from '../data-wp.service';
import { PostI } from '../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-planes',
  templateUrl: './planes.component.html',
  styleUrls: ['./planes.component.css'],
  providers: [DataWpServicePlanesChild, DataWpServicePlanes],
  encapsulation: ViewEncapsulation.None
})

export class PlanesComponent implements OnInit {
  product
  title
  posts$: Observable<PostI[]>;
  mobile: boolean;
  posts: PostI[];
  constructor( 
    private postsService: DataWpServicePlanesChild, 
    private postsServiceSEO: DataWpServicePlanes, 
    private router: Router,
    private titleService: Title, 
    private metaService: Meta ) { }


  ngOnInit() {
    this.postsService.getPosts().subscribe( 
      (posts: PostI[]) => this.posts = posts, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));

      this.posts$ = this.postsServiceSEO.getPosts();
      this.posts$.subscribe(data => { 
        /*Levantamos MetaTags*/
        var titleSeo = this.product=data[0].acf.seo.titulo;
        var descripcionSeo = this.product=data[0].acf.seo.descripcion;
        var keywordsSeo = this.product=data[0].acf.seo.keywords;
        /*Seteamos Metatags en header*/
        this.titleService.setTitle( 'Vittal - ' + titleSeo );
        this.metaService.updateTag( {name: 'keywords', content: keywordsSeo} );
        this.metaService.updateTag( {name: 'description', content: descripcionSeo} );
        this.metaService.updateTag( {name: 'robots', content: 'index, follow'} );       
        
       } 
      )
      window.innerWidth < 481 ? (this.mobile = true) : (this.mobile = false);
  }
  selectPost(slug) {
  	this.router.navigate([slug]);
  }
}
