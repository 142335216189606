import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { API_BASE_URL_JSON } from "src/app/data-wp.service";
import { emailData } from "src/app/shared/emailData";
import { EmpresaData } from "src/app/shared/empresa-data.interface";

@Component({
  selector: "app-reclamo-de-correo-inicial-empresas",
  templateUrl: "./reclamo-de-correo-inicial-empresas.component.html",
  styleUrls: ["../../empresa-gestion-online.component.css"],
})
export class ReclamoDeCorreoInicialEmpresasComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private modalService: NgbModal
  ) {}
  get calle() {
    return this.contactForm.get("calle");
  }
  get altura() {
    return this.contactForm.get("altura");
  }
  get piso() {
    return this.contactForm.get("piso");
  }
  get departamento() {
    return this.contactForm.get("departamento");
  }
  get entreCalles() {
    return this.contactForm.get("entreCalles");
  }
  get localidad() {
    return this.contactForm.get("localidad");
  }
  get cp() {
    return this.contactForm.get("cp");
  }
  get telefono() {
    return this.contactForm.get("telefono");
  }
  get aclaraciones() {
    return this.contactForm.get("aclaraciones");
  }

  form: FormGroup;
  datosEmpresa: EmpresaData;
  testDatos: EmpresaData;
  modalMessage: string;
  @Output() closeModal = new EventEmitter();
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;
  contactForm = this.formBuilder.group({
    calle: ["", [Validators.required]],
    altura: ["", [Validators.required]],
    piso: ["", [Validators.required]],
    departamento: ["", [Validators.required]],
    entreCalles: ["", [Validators.required]],
    localidad: ["", [Validators.required]],
    cp: ["", [Validators.required]],
    telefono: ["", [Validators.required]],
    aclaraciones: [""],
  });

  ngOnInit() {
    this.datosEmpresa = JSON.parse(sessionStorage.getItem("data"));
    //////////////////////////////////////
    /// For Test
    /////////////////////////////////////
    this.testDatos = {
      empresa_cuit: "",
      empresa_codigo: "",
      empresa_razon_social: "",
      empresa_dominio: "",
      empresa_email: "",
      empresa_factura: "",
      empresa_nombre: "",
      empresa_plan_tipo: "",
      empresa_telefono: "",
    };
  }

  submitForm() {
    //this.datosEmpresa = Object.assign({}, this.testDatos);

    let formData = new FormData();
    let form = this.contactForm.value;
    formData.append("empresa_codigo", this.datosEmpresa.empresa_codigo);
    formData.append("empresa_cuit", this.datosEmpresa.empresa_cuit);
    this.datosEmpresa.empresa_nombre.length > 0
      ? formData.append(
          "empresa_razon_social",
          this.datosEmpresa.empresa_nombre
        )
      : formData.append("empresa_razon_social", "NO posee razón social");
    formData.append("empresa_dominio", this.datosEmpresa.empresa_dominio);
    formData.append("empresa_email", this.datosEmpresa.empresa_email);
    formData.append("calle", form.calle);
    formData.append("altura", form.altura);
    formData.append("piso", form.piso);
    formData.append("departamento", form.departamento);
    formData.append("entre_calles", form.entreCalles);
    formData.append("localidad", form.localidad);
    formData.append("codigo_postal", form.cp);
    formData.append("telefono", form.telefono);
    form.aclaraciones.length > 0
      ? formData.append("aclaraciones", form.aclaraciones)
      : formData.append("aclaraciones", " ");

    this.datosEmpresa.empresa_dominio.length > 0
      ? formData.append(
          "email_destino",
          emailData[this.datosEmpresa.empresa_dominio]
        )
      : formData.append("email_destino", "cas@vittal.com.ar");

    this.http
      .post<any>(
        API_BASE_URL_JSON + "/contact-form-7/v1/contact-forms/3819/feedback",
        formData
      )
      .subscribe((res) => {
        console.log(res);
        if ((res.status = "mail_sent")) {
          this.closeModal.emit();
          this.modalMessage = res.message;
          this.openModal(this.modalRespuesta);
        } else {
          this.modalMessage = res.message;
          this.openModal(this.modalRespuesta);
        }
      });
  }
  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }
}
