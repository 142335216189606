import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { DataWpServicePlanes } from '../../data-wp.service';
import { PostI } from '../../post.interface';

@Component({
  selector: 'app-nav-planes',
  templateUrl: './nav-planes.component.html',
  styleUrls: ['./nav-planes.component.css'],
  providers: [DataWpServicePlanes]
})
export class NavPlanesComponent implements OnInit {


  constructor(
    private dataWp: DataWpServicePlanes, 
    private sanitizer: DomSanitizer
    ) { 

    }
  
  posts$: Observable<PostI[]>;
  
  transform(html) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnInit() {
    this.posts$ = this.dataWp.getPosts();
  }  
}
