import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataWpServiceServAdicHome, DataWpServiceServAdicPadre } from '../../data-wp.service';
import { PostI } from '../../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-blog-home',
  templateUrl: './blog-home.component.html',
  styleUrls: ['./blog-home.component.css'],
  providers: [DataWpServiceServAdicHome, DataWpServiceServAdicPadre],
  encapsulation: ViewEncapsulation.None
})
export class BlogHomeComponent implements OnInit {

  posts: PostI[];
  postsPadre: PostI[];
  
  constructor(  private postsService: DataWpServiceServAdicHome,  private postsServicePadre: DataWpServiceServAdicPadre,  private router: Router    ) {}



  ngOnInit() {

    /* PADRE SERVICIOS ADIC: SACO DE ACA EL TITULO  Y LA BAJADA PARA LA HOME */
    this.postsServicePadre.getPosts().subscribe( 
      (postsPadre: PostI[]) => this.postsPadre = postsPadre, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));


      /* HIJOS SERVICIOS ADIC: SACO LISTADO DE SERVICIOS LIMITADO A 6 */
    this.postsService.getPosts().subscribe( 
      (posts: PostI[]) => this.posts = posts, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));
  }
  
  selectPost(slug) {
  	this.router.navigate([slug]);
  }

}
