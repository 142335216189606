import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DataWpServiceEmpresas } from '../../data-wp.service';
import { PostI } from '../../post.interface';
import {DomSanitizer} from '@angular/platform-browser';
import { Observable  } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-empresas-single',
  templateUrl: './empresas-single.component.html',
  styleUrls: ['./empresas-single.component.css'],
  providers: [DataWpServiceEmpresas],
  encapsulation: ViewEncapsulation.None,
})
export class EmpresasSingleComponent implements OnInit {
  product
  title
  posts$: Observable<PostI[]>;

  post: PostI;

  constructor( 
    private postsService: DataWpServiceEmpresas, 
    private route: ActivatedRoute, 
    private sanitizer: DomSanitizer,
    private titleService: Title, 
    private metaService: Meta ) { }

  getPost(slug){
    this.postsService
      .getPost(slug)
      .subscribe(res => {
        this.post = res[0];
      });
  }

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnInit() {

    this.route.params.forEach((params: Params) => {
       let slug = params['slug'];
       this.getPost(slug)
       this.posts$ = this.postsService.getPost(slug);
    });


    this.posts$.subscribe(data => { 
    /*Levantamos MetaTags*/
    var titleSeo = this.product=data[0].acf.seo.titulo;
    var descripcionSeo = this.product=data[0].acf.seo.descripcion;
    var keywordsSeo = this.product=data[0].acf.seo.keywords;
    /*Seteamos Metatags en header*/
    this.titleService.setTitle( 'Vittal - ' + titleSeo );
    this.metaService.updateTag( {name: 'description', content: descripcionSeo} );
    this.metaService.updateTag( {name: 'keywords', content: keywordsSeo} );
    this.metaService.updateTag( {name: 'robots', content: 'index, follow'} );
      } 
    )

  }

}
