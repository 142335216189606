import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AuthService } from "src/app/services/auth.service";
import { SocioData } from "src/app/shared/socio-data.interface";

@Component({
  selector: "app-socios-gestion-online",
  templateUrl: "./socios-gestion-online.component.html",
  styleUrls: ["./socios-gestion-online.component.css"],
})
export class SociosGestionOnlineComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private authSrv: AuthService,
    private spinner: NgxUiLoaderService
  ) {}

  datosSocio: SocioData;
  socio_nombre: string;
  socio_apellido: string;
  socio_numero: string;
  socio_dominio: string;

  ngOnInit() {
    this.datosSocio = this.authSrv.getDataLogin();
    this.socio_numero = this.datosSocio.socio_numero;
    this.socio_apellido = this.datosSocio.socio_apellido;
    this.socio_nombre = this.datosSocio.socio_nombre;
    this.socio_dominio = this.datosSocio.socio_dominio;
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.spinner.stop();
  }

  /*Funcion para abrir modal problemas de acceso*/
  openModalForms(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }
  /*Funcion para abrir modal problemas de acceso*/
  openModalFormsLg(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form-lg",
    });
  }

  /*Funcion para cerrar el modal */

  closeModal() {
    this.modalService.dismissAll();
  }

  logout() {
    this.authSrv.logout();
  }
}
