import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { Location } from "@angular/common";
@Injectable()
export class AuthGuard implements CanActivate{


constructor(private authService: AuthService, private router: Router, private location: Location,) {}

  
  canActivate(   
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    
    const isAuth = sessionStorage.getItem('isLogged');
    
    if (!isAuth) {
      this.router.navigate(['/gestion-online']);
      return false;
    }   
      return true; 
    
  }
}
