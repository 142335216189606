import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { API_BASE_URL_V } from "src/app/data-wp.service";
import { AuthService } from "src/app/services/auth.service";
import { SocioData } from "src/app/shared/socio-data.interface";

@Component({
  selector: "app-pedido-de-factura",
  templateUrl: "./pedido-de-factura.component.html",
  styleUrls: ["./pedido-de-factura.component.css"],
})
export class PedidoDeFacturaComponent implements OnInit {
  constructor(
    private authSrv: AuthService,
    private http: HttpClient,
    private modalService: NgbModal,
    private spinner: NgxUiLoaderService
  ) { }
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;
  datosSocio: SocioData;
  socio_nombre: string;
  socio_apellido: string;
  socio_numero: string;
  socio_dominio: string;
  modalMessage: string;
  facturas;
  ngOnInit() {
    this.datosSocio = JSON.parse(sessionStorage.getItem("data"));
    this.spinner.start();
    this.http
      .get<any>(
        API_BASE_URL_V +
        `/facturas?socio_numero=${this.datosSocio.socio_numero}&socio_dominio=${this.datosSocio.socio_dominio}`
      )
      .subscribe((res) => {
        if (res.success) {
          this.spinner.stop();
          if (res.data.facturas) {
            this.facturas = res.data.facturas;
          }
        } else {
          this.spinner.stop();
          this.modalMessage = res.message;
          this.openModal(this.modalRespuesta);
        }
      });
  }

  logout() {
    this.authSrv.logout();
  }

  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }

  getMonth(fecha) {
    let formatofecha = fecha.split("/");
    let fechafinal =
      formatofecha[1] + "/" + formatofecha[0] + "/" + formatofecha[2];
    return new Intl.DateTimeFormat("es-ES", { month: "long" }).format(
      new Date(fechafinal)
    );
  }
  getYear(fecha) {
    let formatofecha = fecha.split("/");

    return formatofecha[2];
  }

  obtenerFacturaPDF(letra: string, numero: string, fecha: string) {
    this.spinner.start();
    this.http
      .post(API_BASE_URL_V + "/socio/factura/pdf", {
        letra: letra,
        numero: numero,
        dominio: this.datosSocio.socio_dominio,
      }, { responseType: 'blob' })
      .subscribe((res) => {
        this.spinner.stop();

        if (res instanceof Blob) {
          const blob = new Blob([res], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          const mes = this.getMonth(fecha);
          const año = this.getYear(fecha);
          const nombreArchivo = `Factura_${mes}${año}_${numero}.pdf`;

          const a = document.createElement('a');
          a.href = url;
          a.download = nombreArchivo;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          this.modalMessage = "Error: La respuesta no es un PDF válido.";
          this.openModal(this.modalRespuesta);
        }
      }, (error) => {
        this.spinner.stop();
        this.modalMessage = "Error al obtener el PDF.";
        this.openModal(this.modalRespuesta);
      });
  }


}

