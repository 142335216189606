import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-validacion-empresas',
  templateUrl: './validacion-empresas.component.html',
  styleUrls: ['./validacion-empresas.component.css']
})
export class ValidacionEmpresasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
