import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { ParallaxModule } from 'ngx-parallax';
import { HttpClientModule } from '@angular/common/http';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { NgwWowModule } from 'ngx-wow';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, registerLocaleData } from "@angular/common";
import { ShareModule } from "@ngx-share/core";
import { NgxPaginationModule } from "ngx-pagination";
import { TextareaAutosizeModule } from "ngx-textarea-autosize";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// importar locales
import localeEsAr from "@angular/common/locales/es-AR";

// registrar los locales con el nombre que quieras utilizar a la hora de proveer
registerLocaleData(localeEsAr, "es-Ar");

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MenuComponent } from "./menu/menu.component";
import { FooterComponent } from "./footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { SeccionesDestacadasComponent } from "./home/secciones-destacadas/secciones-destacadas.component";
import { SeccionesPrincipalesComponent } from "./home/secciones-principales/secciones-principales.component";
import { SliderComponent } from "./home/slider/slider.component";
import { BannerDestacadoComponent } from "./home/banner-destacado/banner-destacado.component";
import { CotizacionComponent } from "./cotizacion/cotizacion.component";
import { PlanesComponent } from "./planes/planes.component";
import { PlanSingleComponent } from "./planes/plan-single/plan-single.component";

import { NavPlanesComponent } from "./planes/nav-planes/nav-planes.component";
import { SociosComponent } from "./socios/socios.component";
import { CartillaMedicaComponent } from "./socios/cartilla-medica/cartilla-medica.component";
import { CoberturaComponent } from "./socios/cobertura/cobertura.component";
import { ServiciosAdicionalesComponent } from "./servicios-adicionales/servicios-adicionales.component";
import { SinglesComponent } from "./singles/singles.component";
import { ServicioAdicionalSingleComponent } from "./servicios-adicionales/servicio-adicional-single/servicio-adicional-single.component";
import { EnConstruccionComponent } from "./en-construccion/en-construccion.component";
import { NavServiciosComponent } from "./servicios-adicionales/nav-servicios/nav-servicios.component";
import { PromosComponent } from "./planes/promos/promos.component";
import { PromoSingleComponent } from "./planes/promos/promo-single/promo-single.component";
import { NavPromosComponent } from "./planes/promos/nav-promos/nav-promos.component";
import { PreguntasFrecuentesComponent } from "./socios/preguntas-frecuentes/preguntas-frecuentes.component";
import { AreasProtegidasComponent } from "./areas-protegidas/areas-protegidas.component";
import { EmpresasComponent } from "./empresas/empresas.component";
import { LandingsComponent } from "./landings/landings.component";
import { LandingSingleComponent } from "./landings/landing-single/landing-single.component";
import { MedevacComponent } from "./empresas/medevac/medevac.component";
import { Idoc1Component } from "./servicios-adicionales/idoc1/idoc1.component";
import { Idoc2Component } from "./servicios-adicionales/idoc2/idoc2.component";
import { EspacioCardioprotegidoComponent } from "./areas-protegidas/espacio-cardioprotegido/espacio-cardioprotegido.component";
import { CoberturaDeEventosComponent } from "./areas-protegidas/cobertura-de-eventos/cobertura-de-eventos.component";
import { MedicinaLaboralComponent } from "./areas-protegidas/medicina-laboral/medicina-laboral.component";
import { ContactoComponent } from "./contacto/contacto.component";
import { EmpresasDeSaludComponent } from "./empresas/empresas-de-salud/empresas-de-salud.component";
import { OilGasComponent } from "./empresas/oil-gas/oil-gas.component";
import { FranquiciasComponent } from "./franquicias/franquicias.component";
import { InstitucionalComponent } from "./institucional/institucional.component";
import { RrhhComponent } from "./rrhh/rrhh.component";
import { BlogComponent } from "./blog/blog.component";
import { BlogHomeComponent } from "./home/blog-home/blog-home.component";
import { SingleBlogComponent } from "./blog/single-blog/single-blog.component";
import { SideCategoriasComponent } from "./blog/side-categorias/side-categorias.component";
import { NavRelacionadasComponent } from "./blog/nav-relacionadas/nav-relacionadas.component";
import { CategoriaComponent } from "./blog/categoria/categoria.component";
import { ServiciosComponent } from "./areas-protegidas/servicios/servicios.component";
import { FundacionVittalComponent } from "./fundacion-vittal/fundacion-vittal.component";
import { Platform } from "@angular/cdk/platform";
import { FormContactoComponent } from "./landings/forms/form-contacto/form-contacto.component";
import { FormCotizarComponent } from "./landings/forms/form-cotizar/form-cotizar.component";
import { FormCotizadoresComponent } from "./landings/forms/form-cotizadores/form-cotizadores.component";
import { CalculadorComponent } from "./landings/forms/form-cotizadores/calculador/calculador.component";
import { ChatbotComponent } from "./footer/chatbot/chatbot.component";
import { BuscadorComponent } from "./menu/buscador/buscador.component";
import { FilterPipe } from "./menu/buscador/filter.pipe";
import { AreasSingleComponent } from "./areas-protegidas/areas-single/areas-single.component";
import { EmpresasSingleComponent } from "./empresas/empresas-single/empresas-single.component";
import { IdocComponent } from "./servicios-adicionales/idoc/idoc.component";
import { ListComponent } from "./blog/categoria/list/list.component";
import { BannerComponent } from "./cotizacion/banner/banner.component";
import { SliderMobileComponent } from "./home/slider-mobile/slider-mobile.component";
import { GestionOnlineComponent } from "./gestion-online/gestion-online.component";
import { LoginComponent } from "./gestion-online/login/login.component";
import { LoginSocioComponent } from "./gestion-online/login/login-socio/login-socio.component";
import { LoginEmpresaComponent } from "./gestion-online/login/login-empresa/login-empresa.component";
import { RegistroSocioComponent } from "./gestion-online/registro/registro-socio/registro-socio.component";
import { RegistroEmpresaComponent } from "./gestion-online/registro/registro-empresa/registro-empresa.component";
import { SociosGestionOnlineComponent } from "./gestion-online/socios-gestion-online/socios-gestion-online.component";
import { SolicitudDeCredencialComponent } from "./gestion-online/socios-gestion-online/forms/solicitud-de-credencial/solicitud-de-credencial.component";
import { AmpliacionFamiliarComponent } from "./gestion-online/socios-gestion-online/forms/ampliacion-familiar/ampliacion-familiar.component";
import { ReclamoDeCobradorComponent } from "./gestion-online/socios-gestion-online/forms/reclamo-de-cobrador/reclamo-de-cobrador.component";
import { CambioDeDomicilioComponent } from "./gestion-online/socios-gestion-online/forms/cambio-de-domicilio/cambio-de-domicilio.component";
import { CambioDeFormaDePagoComponent } from "./gestion-online/socios-gestion-online/forms/cambio-de-forma-de-pago/cambio-de-forma-de-pago.component";
import { ReclamoDeCorreoInicialComponent } from "./gestion-online/socios-gestion-online/forms/reclamo-de-correo-inicial/reclamo-de-correo-inicial.component";
import { ModificacionDeDatosComponent } from "./gestion-online/socios-gestion-online/forms/modificacion-de-datos/modificacion-de-datos.component";
import { ConstanciaDeAtencionComponent } from "./gestion-online/socios-gestion-online/forms/constancia-de-atencion/constancia-de-atencion.component";
import { ConsultasSugerenciasComponent } from "./gestion-online/socios-gestion-online/forms/consultas-sugerencias/consultas-sugerencias.component";
import { CredencialComponent } from "./gestion-online/socios-gestion-online/credencial/credencial.component";
import { PedidoDeFacturaComponent } from "./gestion-online/socios-gestion-online/pedido-de-factura/pedido-de-factura.component";
import { PerfilUsuarioComponent } from "./gestion-online/socios-gestion-online/perfil-usuario/perfil-usuario.component";
import {
  MatButtonToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatInputModule,
  MatRadioModule,
} from "@angular/material";
import { DataWpServiceGestionOnline } from "./data-wp.service";
import { AuthService } from "./services/auth.service";
import { LibroQuejasComponent } from "./footer/libro-quejas/libro-quejas.component";
import { EmpresaGestionOnlineComponent } from "./gestion-online/empresa-gestion-online/empresa-gestion-online.component";
import { EmpresaFacturacionComponent } from "./gestion-online/empresa-gestion-online/empresa-facturacion/empresa-facturacion.component";
import { ReclamoDeCobradorEmpresasComponent } from "./gestion-online/empresa-gestion-online/forms/reclamo-de-cobrador-empresas/reclamo-de-cobrador-empresas.component";
import { CambioDomicilioEmpresasComponent } from "./gestion-online/empresa-gestion-online/forms/cambio-domicilio-empresas/cambio-domicilio-empresas.component";
import { ConstanciaAtencionEmpresasComponent } from "./gestion-online/empresa-gestion-online/forms/constancia-atencion-empresas/constancia-atencion-empresas.component";
import { ConsultaSugerenciasEmpresasComponent } from "./gestion-online/empresa-gestion-online/forms/consulta-sugerencias-empresas/consulta-sugerencias-empresas.component";
import { CambioDeTitularidadComponent } from "./gestion-online/empresa-gestion-online/forms/cambio-de-titularidad/cambio-de-titularidad.component";
import { ReclamoDeCorreoInicialEmpresasComponent } from "./gestion-online/empresa-gestion-online/forms/reclamo-de-correo-inicial-empresas/reclamo-de-correo-inicial-empresas.component";
import { PedidoDeContratoComponent } from "./gestion-online/empresa-gestion-online/forms/pedido-de-contrato/pedido-de-contrato.component";
import { ExtensionAreaProtegidaComponent } from "./gestion-online/empresa-gestion-online/forms/extension-area-protegida/extension-area-protegida.component";
import { FormContactoBitrixComponent } from "./landings/forms/form-contacto-bitrix/form-contacto-bitrix.component";
import { PerfilEmpresaComponent } from "./gestion-online/empresa-gestion-online/perfil-empresa/perfil-empresa.component";
import { ConstanciaDeAsociacionComponent } from "./gestion-online/empresa-gestion-online/forms/constancia-de-asociacion/constancia-de-asociacion.component";
import { ValidacionSociosComponent } from './gestion-online/registro/validacion-socios/validacion-socios.component';
import { ValidacionEmpresasComponent } from './gestion-online/registro/validacion-empresas/validacion-empresas.component';
import { EcommerceComponent } from './ecommerce/ecommerce.component';
import { ThankyouComponentEcommerce } from './ecommerce/thankyou/thankyou.component';
import { TelefonosUtilesComponent } from './socios/telefonos-utiles/telefonos-utiles.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

/*LOADER*/
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  maxTime: -1,
  minTime: 1000,
  pbThickness: 5,
  pbColor: "#5AD6C6",
  fgsColor: "#5AD6C6",
  overlayColor: "rgba(255,255,255,.8)",
  fgsType: "ball-spin",
  //fgsSize: 100,
  //logoUrl: '../assets/img/isologo-vittal.svg'
};

@Pipe({ name: "safe" })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    HomeComponent,
    SeccionesDestacadasComponent,
    SeccionesPrincipalesComponent,
    SliderComponent,
    BannerDestacadoComponent,
    CotizacionComponent,
    PlanesComponent,
    NavPlanesComponent,
    SociosComponent,
    CartillaMedicaComponent,
    CoberturaComponent,
    ServiciosAdicionalesComponent,
    SafePipe,
    PlanSingleComponent,
    SinglesComponent,
    ServicioAdicionalSingleComponent,
    EnConstruccionComponent,
    NavServiciosComponent,
    PromosComponent,
    PromoSingleComponent,
    NavPromosComponent,
    PreguntasFrecuentesComponent,
    AreasProtegidasComponent,
    EmpresasComponent,
    LandingsComponent,
    LandingSingleComponent,
    MedevacComponent,
    Idoc1Component,
    Idoc2Component,
    EspacioCardioprotegidoComponent,
    CoberturaDeEventosComponent,
    MedicinaLaboralComponent,
    ContactoComponent,
    EmpresasDeSaludComponent,
    OilGasComponent,
    FranquiciasComponent,
    InstitucionalComponent,
    RrhhComponent,
    BlogComponent,
    BlogHomeComponent,
    SingleBlogComponent,
    SideCategoriasComponent,
    NavRelacionadasComponent,
    CategoriaComponent,
    ServiciosComponent,
    FundacionVittalComponent,
    FormContactoComponent,
    FormCotizarComponent,
    FormCotizadoresComponent,
    CalculadorComponent,
    ChatbotComponent,
    BuscadorComponent,
    FilterPipe,
    AreasSingleComponent,
    EmpresasSingleComponent,
    IdocComponent,
    ListComponent,
    BannerComponent,
    SliderMobileComponent,
    GestionOnlineComponent,
    LoginComponent,
    LoginSocioComponent,
    LoginEmpresaComponent,
    RegistroSocioComponent,
    RegistroEmpresaComponent,
    SociosGestionOnlineComponent,
    SolicitudDeCredencialComponent,
    AmpliacionFamiliarComponent,
    ReclamoDeCobradorComponent,
    CambioDeDomicilioComponent,
    CambioDeFormaDePagoComponent,
    ReclamoDeCorreoInicialComponent,
    ModificacionDeDatosComponent,
    ConstanciaDeAtencionComponent,
    ConsultasSugerenciasComponent,
    CredencialComponent,
    PedidoDeFacturaComponent,
    PerfilUsuarioComponent,
    LibroQuejasComponent,
    EmpresaGestionOnlineComponent,
    EmpresaFacturacionComponent,
    ReclamoDeCobradorEmpresasComponent,
    CambioDomicilioEmpresasComponent,
    ConstanciaAtencionEmpresasComponent,
    ConsultaSugerenciasEmpresasComponent,
    CambioDeTitularidadComponent,
    ReclamoDeCorreoInicialEmpresasComponent,
    PedidoDeContratoComponent,
    ExtensionAreaProtegidaComponent,
    FormContactoBitrixComponent,
    PerfilEmpresaComponent,
    ConstanciaDeAsociacionComponent,
    ValidacionSociosComponent,
    ValidacionEmpresasComponent,
    EcommerceComponent,
    ThankyouComponentEcommerce,
    TelefonosUtilesComponent,
    ThankyouComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ScrollToModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBdogmv7ZrasC4F8f4n7gt1SeCZPGkdPwQ",
    }),
    ParallaxModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    NgwWowModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ShareModule,
    NgxPaginationModule,
    TextareaAutosizeModule,
    MatSelectModule,
    MatFormFieldModule,
    MatGridListModule,
    MatButtonToggleModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatDividerModule,
    MatChipsModule,
    MatCheckboxModule,
    MatRadioModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es-Ar" },
    Platform,
    DataWpServiceGestionOnline,
    AuthService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
