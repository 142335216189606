import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { API_BASE_URL_V } from "src/app/data-wp.service";
import { SessionStorageService } from "src/app/services/session-storage.service";
import { EmpresaData } from "src/app/shared/empresa-data.interface";

@Component({
  selector: "app-login-empresa",
  templateUrl: "./login-empresa.component.html",
  styleUrls: ["./login-empresa.component.css"],
})
export class LoginEmpresaComponent implements OnInit {
  form: FormGroup;
  isHome;
  isMobile: boolean;
  screenSize;
  datosEmpresa: EmpresaData;
  modalMessage: string;

  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private ssService: SessionStorageService,
    private spinner: NgxUiLoaderService,
    private http: HttpClient,
    private modalService: NgbModal
  ) {}

  get cuitEmpresa() {
    return this.contactForm.get("cuitEmpresa");
  }
  get passwordEmpresa() {
    return this.contactForm.get("passwordEmpresa");
  }

  contactForm = this.formBuilder.group({
    cuitEmpresa: ["", [Validators.required, Validators.pattern("^[0-9]{11}$")]],
    passwordEmpresa: ["", [Validators.required]],
  });

  submitLoginFormEmpresa() {
    let cuit_empresa = this.contactForm.value.cuitEmpresa;
    let password_empresa = this.contactForm.value.passwordEmpresa;
    this.spinner.start();
    let formdata = new FormData();
    formdata.append("empresa_cuit", cuit_empresa);
    formdata.append("empresa_password", password_empresa);
    this.http
      .post<any>(API_BASE_URL_V + "/empresa/signin", formdata)
      .subscribe((res) => {
        //console.log(res);
        if (res.success) {
          this.datosEmpresa = res.data;
          this.saveAuthData(this.datosEmpresa);
          this.router.navigate(["atencion-al-socio/gestion-online/empresas"]);
        } else {
          this.spinner.stop();
          this.modalMessage = res.message;
          this.openModal(this.modalRespuesta);
        }
      });
  }

  /*Funcion para abrir registro Empresa*/
  abrirFormSocios() {
    document.getElementById("contentRegistro").classList.add("show");
    document.getElementById("registroEmpresa").classList.add("show");
    document.getElementById("contentLogin").classList.remove("show");
    // if (window.innerWidth < 760) {
    //   document.getElementById("titulosMobile").classList.add("none");
    // }
    this.ssService.setRegister();
    this.ssService.clearHome();
  }

  ngOnInit() {
    this.ssService.isHome().subscribe((e) => {
      this.isHome = e;
    });
  }

  private saveAuthData(data) {
    sessionStorage.setItem("data", JSON.stringify(data));
    sessionStorage.setItem("isLogged", "true");
  }

  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }
}
