import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PlanesComponent } from './planes/planes.component';
import { SociosComponent } from './socios/socios.component';
import { CartillaMedicaComponent } from './socios/cartilla-medica/cartilla-medica.component';
import { CoberturaComponent } from './socios/cobertura/cobertura.component';
import { ServiciosAdicionalesComponent } from './servicios-adicionales/servicios-adicionales.component'
import { SinglesComponent } from './singles/singles.component';
import { EnConstruccionComponent } from './en-construccion/en-construccion.component';
import { PreguntasFrecuentesComponent } from './socios/preguntas-frecuentes/preguntas-frecuentes.component';
import { AreasProtegidasComponent } from './areas-protegidas/areas-protegidas.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { LandingsComponent } from './landings/landings.component';
import { LandingSingleComponent } from './landings/landing-single/landing-single.component';
import { ServicioAdicionalSingleComponent } from './servicios-adicionales/servicio-adicional-single/servicio-adicional-single.component';
import { ContactoComponent } from './contacto/contacto.component';
import { FranquiciasComponent } from './franquicias/franquicias.component';
import { InstitucionalComponent } from './institucional/institucional.component';
import { RrhhComponent } from './rrhh/rrhh.component';
import { BlogComponent } from './blog/blog.component';
import { SingleBlogComponent } from './blog/single-blog/single-blog.component';
import { CategoriaComponent } from './blog/categoria/categoria.component';
import { FundacionVittalComponent } from './fundacion-vittal/fundacion-vittal.component';
import { AreasSingleComponent } from './areas-protegidas/areas-single/areas-single.component'
import { EmpresasSingleComponent } from './empresas/empresas-single/empresas-single.component';
import { GestionOnlineComponent } from './gestion-online/gestion-online.component';
import { SociosGestionOnlineComponent } from './gestion-online/socios-gestion-online/socios-gestion-online.component';
import { CredencialComponent } from './gestion-online/socios-gestion-online/credencial/credencial.component';
import { PedidoDeFacturaComponent } from './gestion-online/socios-gestion-online/pedido-de-factura/pedido-de-factura.component';
import { PerfilUsuarioComponent } from './gestion-online/socios-gestion-online/perfil-usuario/perfil-usuario.component';
import { AuthGuard } from './services/auth.guard';
import { LibroQuejasComponent } from './footer/libro-quejas/libro-quejas.component';
import { EmpresaGestionOnlineComponent } from './gestion-online/empresa-gestion-online/empresa-gestion-online.component';
import { EmpresaFacturacionComponent } from "./gestion-online/empresa-gestion-online/empresa-facturacion/empresa-facturacion.component";
import { PerfilEmpresaComponent } from "./gestion-online/empresa-gestion-online/perfil-empresa/perfil-empresa.component";
import { ValidacionSociosComponent } from "./gestion-online/registro/validacion-socios/validacion-socios.component";
import { PromosComponent } from './planes/promos/promos.component';
import { PromoSingleComponent } from './planes/promos/promo-single/promo-single.component';
import { MedevacComponent } from './empresas/medevac/medevac.component';
import { OilGasComponent } from './empresas/oil-gas/oil-gas.component';
import { EcommerceComponent } from './ecommerce/ecommerce.component';
import { ThankyouComponentEcommerce } from './ecommerce/thankyou/thankyou.component';
import { TelefonosUtilesComponent } from './socios/telefonos-utiles/telefonos-utiles.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", redirectTo: "", pathMatch: "full" },
  
  { path: "planes", component: PlanesComponent },
  { path: "ecommerce/:plan", component: EcommerceComponent },
  { path: "thankyou", component: ThankyouComponent },
  { path: "ecommerce/thankyou", component: ThankyouComponentEcommerce },
  { path: "gracias-dental-center-no-socios", component: ThankyouComponent },
  { path: "gracias-plan-plus-visitas-medicas-sin-cargo", component: ThankyouComponent },
  { path: "gracias-apto-fisico-escolar-no-socios", component: ThankyouComponent },
  { path: "gracias-vittal-clarin-365-beneficios-30-descuento-mix-plus", component: ThankyouComponent },
  { path: "gracias-dental-center-promo-3-meses-membresia", component: ThankyouComponent },
  { path: "gracias-cuidado-los-adultos-mayores-prioridad", component: ThankyouComponent },
  { path: "gracias-vacuna-antigripal-asociate-ahora", component: ThankyouComponent },
  { path: "gracias-plan-mix-promo-6x6", component: ThankyouComponent },
  { path: "gracias-contacto", component: ThankyouComponent },
  { path: "gracias-area-protegida", component: ThankyouComponent },
  { path: "gracias-servicios-aeromedicos", component: ThankyouComponent },
  { path: "gracias-franquicias", component: ThankyouComponent },  
  { path: "atencion-al-socio", component: SociosComponent },
  { path: "atencion-al-socio/gestion-online", component: GestionOnlineComponent },
  {
    path: "atencion-al-socio/gestion-online/socios",
    component: SociosGestionOnlineComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "atencion-al-socio/gestion-online/socios/credencial",
    component: CredencialComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "atencion-al-socio/gestion-online/socios/pedido-de-factura",
    component: PedidoDeFacturaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "atencion-al-socio/gestion-online/socios/perfil-usuario",
    component: PerfilUsuarioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "atencion-al-socio/gestion-online/empresas",
    component: EmpresaGestionOnlineComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "atencion-al-socio/gestion-online/empresas/perfil-empresa",
    component: PerfilEmpresaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "atencion-al-socio/gestion-online/empresas/empresa-facturacion",
    component: EmpresaFacturacionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "validacion-socios/:id",
    component: ValidacionSociosComponent,
  },
  { path: "atencion-al-socio/cartilla-medica", component: CartillaMedicaComponent },
  { path: "atencion-al-socio/cobertura", component: CoberturaComponent },
  { path: "telefonos-utiles", component: TelefonosUtilesComponent },
  { path: "preguntas-frecuentes", component: PreguntasFrecuentesComponent },
  { path: "servicios-adicionales", component: ServiciosAdicionalesComponent },
  {
    path: "servicios-adicionales/:slug",
    component: ServicioAdicionalSingleComponent,
  },
  { path: "en-construccion", component: EnConstruccionComponent },
  { path: "empresas", component: AreasProtegidasComponent },
  { path: "empresas/:slug", component: AreasSingleComponent },
  { path: "areas-protegidas", component: AreasProtegidasComponent },
  { path: "areas-protegidas/:slug", component: AreasSingleComponent },
  { path: "medicina-corporativa", component: EmpresasComponent },
  { path: "medicina-corporativa/:slug", component: EmpresasSingleComponent },
  { path: "landing", component: LandingsComponent },
  { path: "landing/:slug", component: LandingSingleComponent },
  { path: "institucional/acerca-de-nosotros", component: InstitucionalComponent },
  { path: "fundacion-vittal", component: FundacionVittalComponent },
  { path: "contacto", component: ContactoComponent },
  { path: "institucional/recursos-humanos", component: RrhhComponent },
  { path: "franquicias/sistema-de-franquicias", component: FranquiciasComponent },
  { path: "blog", component: BlogComponent },
  { path: "blog/:slug", component: SingleBlogComponent},    
  { path: "blog/cat/:slug", component: CategoriaComponent },
  { path: ":slug", component: SinglesComponent },
  { path: "planes/:slug", component: SinglesComponent },
  { path: "planes-de-salud/:slug", component: SinglesComponent },
  { path: "promos", component: PromosComponent },
  { path: "promos/:slug", component: PromoSingleComponent },
  { path: "footer/libro-quejas", component: LibroQuejasComponent },
  { path: "aeromedicina/servicios-aeromedicos-y-traslados-aereos", component: MedevacComponent },
  { path: "empresas/oil-gas", component: OilGasComponent },
  { path: 'pagenotfound', component: PageNotFoundComponent },
  { path: '**', redirectTo: 'pagenotfound' },
];

@NgModule({
  imports: [RouterModule.forRoot( routes )],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
