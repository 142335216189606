import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataWpServicePromos, DataWpServicePromosPadre } from '../../data-wp.service';
import { PostI } from '../../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-promos',
  templateUrl: './promos.component.html',
  styleUrls: ['./promos.component.css'],
  providers: [DataWpServicePromos, DataWpServicePromosPadre],
  encapsulation: ViewEncapsulation.None
})
export class PromosComponent implements OnInit {
  product
  title
  posts$: Observable<PostI[]>;
  mobile: boolean;
  posts: PostI[];
  postPromoPadre: PostI[];
  constructor( 
    private postsService: DataWpServicePromos, 
    private postsServicePromosPadre: DataWpServicePromosPadre, 
    private router: Router,
    private titleService: Title, 
    private metaService: Meta) {   }

  ngOnInit() {
    window.innerWidth < 481 ? (this.mobile = true) : (this.mobile = false);
    /* PROMO PADRE */
    this.postsServicePromosPadre.getPosts().subscribe( 
      (postPromoPadre: PostI[]) => this.postPromoPadre = postPromoPadre, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));

    /*HIJOS DE PROMO*/
    this.postsService.getPosts().subscribe( 
      (posts: PostI[]) => this.posts = posts, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));

      this.posts$ = this.postsServicePromosPadre.getPosts();
      this.posts$.subscribe(data => { 
        /*Levantamos MetaTags*/
        var titleSeo = this.product=data[0].acf.seo.titulo;
        var descripcionSeo = this.product=data[0].acf.seo.descripcion;
        var keywordsSeo = this.product=data[0].acf.seo.keywords;
        /*Seteamos Metatags en header*/
        this.titleService.setTitle( 'Vittal - ' + titleSeo );
        this.metaService.updateTag( {name: 'keywords', content: keywordsSeo} );
        this.metaService.updateTag( {name: 'description', content: descripcionSeo} );
        this.metaService.updateTag( {name: 'robots', content: 'index, follow'} );
        console.log(data[0]);
        
       } 
      )

  }
  selectPost(slug) {
  	this.router.navigate([slug]);
  }



}
