import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-idoc2',
  templateUrl: './idoc2.component.html',
  styleUrls: ['./idoc2.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class Idoc2Component implements OnInit {

  
  sliderHeader = [
    {img: 'assets/img/idoc2.jpg'},
    {img: 'assets/img/idoc2-2.jpg'}
  ]

  sliderVentajas = [
    {img: 'assets/img/presentacion-1.png'},    
  ]

  slides = [
    {img: "assets/img/idoc1-pic-slider-1.jpg", titulo: 'Golpes y traumatismos'},
    {img: "assets/img/idoc1-pic-slider-2.jpg", titulo: 'Alergias y erupciones'},
    {img: "assets/img/idoc1-pic-slider-3.jpg", titulo: 'Malestar gastrointestinal'},
    {img: "assets/img/idoc1-pic-slider-4.jpg", titulo: 'Dolor de cabeza'},
    {img: "assets/img/idoc1-pic-slider-5.jpg", titulo: 'Resfríos'},
    {img: "assets/img/idoc1-pic-slider-6.jpg", titulo: 'Tos'},
  ];

  slideConfig = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: '0px',
          slidesToShow: 1
        }
      }
    ]
  };

  constructor() { }

  ngOnInit() {
  }

}
