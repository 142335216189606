import { Component, OnInit } from '@angular/core';
import { PostI } from '../post.interface';
import { Observable } from 'rxjs';
import { DataWpServiceThankYouPage } from '../data-wp.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css'],
  providers: [DataWpServiceThankYouPage]
})
export class ThankyouComponent implements OnInit {

  constructor(private thankyouService: DataWpServiceThankYouPage) { }
  posts$: Observable<PostI[]>;
  posts;
  html;
  ngOnInit() {
    this.thankyouService.getPosts().subscribe(
      (posts: PostI[]) => {
        this.posts = posts;
                        
      },
      (err: HttpErrorResponse) =>
        err.error instanceof Error
          ? console.log("An error occurred:", err.error.message)
          : console.log(
              `Backend returned code ${err.status}, body was: ${err.error}`
            )
    );  
  }

}
