import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataWpServiceCobertura } from '../../data-wp.service';
import { PostI } from '../../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import { Observable  } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { hostname } from 'os';

@Component({
  selector: 'app-cobertura',
  templateUrl: './cobertura.component.html',
  styleUrls: ['./cobertura.component.css'],
  providers: [DataWpServiceCobertura],
  encapsulation: ViewEncapsulation.None
})
export class CoberturaComponent implements OnInit {
  product
  title
  posts$: Observable<PostI[]>;
  viewSize;
  posts: PostI[];
  nombre;
  direccion;
  telefono_administrativo;
  telefono_emergencia;
  email; 
  horario;

  isMobile:boolean;
  

  constructor( 
    config: NgbCarouselConfig, 
    private postsService: DataWpServiceCobertura, 
    private router: Router,
    private titleService: Title, 
    private metaService: Meta  ) { 
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
  }

  ngOnInit() {
    this.postsService.getPosts().subscribe( 
      (posts: PostI[]) => {this.posts = posts; console.log(posts);
      }, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));

      this.posts$ = this.postsService.getPosts();
      this.posts$.subscribe(data => { 
        /*Levantamos MetaTags*/
        var titleSeo = this.product=data[0].acf.seo.titulo;
        var descripcionSeo = this.product=data[0].acf.seo.descripcion;
        var keywordsSeo = this.product=data[0].acf.seo.keywords;
        /*Seteamos Metatags en header*/
        this.titleService.setTitle( 'Vittal - ' + titleSeo );
        this.metaService.updateTag( {name: 'description', content: descripcionSeo} );
        this.metaService.updateTag( {name: 'keywords', content: keywordsSeo} );
        this.metaService.updateTag( {name: 'robots', content: 'index, follow'} );
       } 
      )
      this.viewSize = window.innerWidth;
      this.isMobile = this.viewSize < 480 ? true : false;      
  }
  selectPost(slug) {
  	this.router.navigate([slug]);
  }

  /* Ver listado de cobertura */
  verLista(){
    document.getElementById("itemsResultados").classList.remove("none");
    document.getElementById("mapaSlingle").classList.add("none");
    document.getElementById("mapaList").classList.add("none");
    document.getElementById("btnLista").classList.add("active");    
    document.getElementById("btnMapa").classList.remove("active");
  }

  /* Ver listado de cobertura */
  verMapaList(){
    document.getElementById("itemsResultados").classList.add("none");
    document.getElementById("mapaSlingle").classList.add("none");
    document.getElementById("mapaList").classList.remove("none");
    document.getElementById("btnLista").classList.remove("active");    
    document.getElementById("btnMapa").classList.add("active");
  }


  /* VARIABLES GOOLE MAPS */
  /* GENERALES */

  lat: number = -33.5644088;
  lng: number = -63.9277221;
  latSingle: number = -33.5644088;
  lngSingle: number = -63.9277221;
  zoom: number = 6;
  zoomSingle: number = 14;
  scrollwheel: false
  abrirInterior(){
    alert('abrio');
  }
  

  verSingleResults( nombre, direccion, telefono_administrativo, telefono_emergencia, email, horario, latSingle1, lngSingle1 ){
    
    //alert( nombre + '\n' + direccion + '\n' + telefono_administrativo + '\n' + telefono_emergencia + '\n' + email + '\n' + horario + '\n' + lat + '\n' + long + '\n' + imagen )

    this.nombre = nombre;
    this.direccion = direccion;
    this.telefono_administrativo = telefono_administrativo;
    this.telefono_emergencia = telefono_emergencia;
    this.email = email;
    this.horario = horario;  

    // document.getElementById("nombreSingle").innerHTML = nombre;
    // document.getElementById("nombreSingleMk").innerHTML = nombre + ' ';
    // document.getElementById("dirSingle").innerHTML = direccion;
    // document.getElementById("dirSingleMk").innerHTML = direccion;
    // document.getElementById("telSingle").innerHTML = telefono_administrativo;
    // document.getElementById("telSingleMk").innerHTML = telefono_administrativo + ' ';    
    // document.getElementById("emeSingle").innerHTML = telefono_emergencia;    
    // document.getElementById("horSingle").innerHTML = horario;
    // document.getElementById("maiSingle").innerHTML = email;
    document.getElementById("itemsResultados").classList.add("none");
    document.getElementById("mapaSlingle").classList.remove("none");
    document.getElementById("mapaList").classList.add("none");
    document.getElementById("btnLista").classList.remove("active");    
    document.getElementById("btnMapa").classList.remove("active"); 
    
    this.latSingle = parseFloat(latSingle1);
    this.lngSingle = parseFloat(lngSingle1);
    this.zoomSingle = parseFloat('15');
    
  }



}
