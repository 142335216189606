import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { phoneNumberValidator } from '../contacto/validators/phone-validator'
import { API_BASE_URL_JSON } from '../data-wp.service';
@Component({
  selector: "app-rrhh",
  templateUrl: "./rrhh.component.html",
  styleUrls: ["./rrhh.component.css"],
})
export class RrhhComponent implements OnInit {
  @ViewChild("labelImport", { static: false })
  labelImport: ElementRef;
  formImport: FormGroup;
  fileToUpload: File = null;
  form: FormGroup;
  errorMessage;

  constructor(public formBuilder: FormBuilder, private http: HttpClient) {
    // this.form = this.formBuilder.group({
    //   areas: [""],
    //   nombres: [""],
    //   apellido: [""],
    //   email: [""],
    //   telefono_car: [""],
    //   telefono_num: [""],
    //   provincia: [""],
    //   ciudad: [""],
    //   importFile: [""],
    //   mensaje: [""],
    // });
  }

  onFileChange(files: FileList) {
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map((f) => f.name)
      .join(", ");
    this.fileToUpload = files.item(0);
  }

  get areas() {
    return this.contactForm.get("area");
  }
  get nombres() {
    return this.contactForm.get("nombres");
  }
  get apellido() {
    return this.contactForm.get("apellido");
  }
  get email() {
    return this.contactForm.get("email");
  }
  get telefono_car() {
    return this.contactForm.get("telefono_car");
  }
  get telefono_num() {
    return this.contactForm.get("telefono_num");
  }
  get provincia() {
    return this.contactForm.get("provincia");
  }
  get ciudad() {
    return this.contactForm.get("ciudad");
  }
  get importFile() {
    return this.contactForm.get("importFile");
  }
  get mensaje() {
    return this.contactForm.get("mensaje");
  }

  contactForm = this.formBuilder.group({
    area: ["RRHH"],
    nombres: ["", [Validators.required, Validators.minLength(3)]],
    apellido: ["", [Validators.required, Validators.minLength(3)]],
    email: [
      "",
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    ],
    telefono_car: [
      "",
      [
        Validators.required,
        Validators.pattern("[0-9]+"),
        Validators.minLength(2),
      ],
    ],
    telefono_num: [
      "",
      [
        Validators.required,
        Validators.pattern("[0-9]+"),
        Validators.minLength(6),
      ],
    ],
    provincia: ["", [Validators.required]],
    ciudad: ["", [Validators.required]],
    importFile: [null, [Validators.required]],
    mensaje: ["", [Validators.required, Validators.minLength(20)]],
  });

  ngOnInit() {}
  submitForm() {
    var formData: any = new FormData();
    //formData.append("area", this.contactForm.get('area').value);
    formData.append("nombres", this.contactForm.get("nombres").value);
    formData.append("apellido", this.contactForm.get("apellido").value);
    formData.append("email", this.contactForm.get("email").value);
    formData.append("telefono_car", this.contactForm.get("telefono_car").value);
    formData.append("telefono_num", this.contactForm.get("telefono_num").value);
    formData.append("provincia", this.contactForm.get("provincia").value);
    formData.append("ciudad", this.contactForm.get("ciudad").value);
    formData.append("importFile", this.fileToUpload);
    formData.append("mensaje", this.contactForm.get("mensaje").value);

    this.http
      .post<any>(
        API_BASE_URL_JSON + "/contact-form-7/v1/contact-forms/2097/feedback",
        formData
      )
      .subscribe(
        (response) => {
          if ((response.status = "mail_sent") && !response.invalidFields) {
            this.sendForm();
          } else {
            this.errorMessage = response.message;
            document.getElementById("sendError").classList.add("show");
          }
        },
        (error) => console.log(error)
      );
  }
  sendForm() {
    document.getElementById("sendOk").classList.add("show");
  }

  otraConsulta() {
    location.reload();
    return false;
  }

  spanTop() {
    document.getElementById("areaSpan").classList.add("top");
    document.getElementById("contentSelect").classList.add("ok");
  }

  setDirty() {
    this.importFile.markAsDirty();
  }
}
