import { Component, OnInit } from '@angular/core';
import { DataWpServiceBlog } from '../../data-wp.service';
import { PostI } from '../../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-nav-relacionadas',
  templateUrl: './nav-relacionadas.component.html',
  styleUrls: ['./nav-relacionadas.component.css'],
  providers: [DataWpServiceBlog]
})
export class NavRelacionadasComponent implements OnInit {

slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1
        }
      }
    ]
  };
  

  posts: PostI[];
  constructor( private postsService: DataWpServiceBlog, private router: Router) { }
  ngOnInit() {
    this.postsService.getPosts().subscribe( 
      (posts: PostI[]) => this.posts = posts, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));
  }
  selectPost(slug) {
  	this.router.navigate([slug]);
  }


}
