import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { DataWpServicePrincipales } from '../../data-wp.service';
import { PostI } from '../../post.interface';


@Component({
  selector: 'app-secciones-principales',
  templateUrl: './secciones-principales.component.html',
  styleUrls: ['./secciones-principales.component.css'],
  providers: [DataWpServicePrincipales]
})
export class SeccionesPrincipalesComponent implements OnInit {

  constructor(
    private dataWp: DataWpServicePrincipales, 
    private sanitizer: DomSanitizer
    ) { 

    }
  
  posts$: Observable<PostI[]>;
  
  transform(html) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnInit() {
    this.posts$ = this.dataWp.getPosts();
  }  

}
