import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empresas-de-salud',
  templateUrl: './empresas-de-salud.component.html',
  styleUrls: ['./empresas-de-salud.component.css']
})
export class EmpresasDeSaludComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
