import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DataWpServiceBlogSingles, DataWpServicePages } from '../data-wp.service';
import { PostI } from '../post.interface';

@Component({
  selector: 'app-singles',
  templateUrl: './singles.component.html',
  styleUrls: ['./singles.component.css'],
  providers: [DataWpServicePages, DataWpServiceBlogSingles]
})
export class SinglesComponent implements OnInit {

  post: PostI;
  isBlog: boolean;
  postSubscriptionCompleted: boolean;
  blogSubscriptionCompleted: boolean;
  constructor( private postsService: DataWpServicePages, private route: ActivatedRoute, private postsBlogService: DataWpServiceBlogSingles ) { }

  getPost(slug){
    this.postsService
      .getPost(slug)
      .subscribe(res => {
        if(res[0]){
          this.post = res[0];
          this.isBlog = false;          
        }    
      },
      err =>{
        console.log(err);        
      }
      ,() => {
        this.postSubscriptionCompleted = true; // Marca la suscripción como completada
      });
      
    this.postsBlogService.getPost(slug).subscribe(res=>{
      if (res[0]) {
        this.post = res [0]
        this.isBlog = true;        
      }
    },
    err =>{
      console.log(err);        
    }
    ,() => {
      this.blogSubscriptionCompleted = true; // Marca la suscripción como completada
    });    
  }

  ngOnInit() {

    this.route.params.forEach((params: Params) => {
       let slug = params['slug'];             
       this.getPost(slug)
    });

  }

}
